/**
 * Created by PRIME AI.
 * @author Samuel Hinchliffe
 *
 * @summary The job of this script is to load the files for FCBP for this retailer.
 * In this case, we know of the exact files to load this retailer. This file will be much
 * like an environment file.
 *
 * Created at: 02/10/2023
 * @see       [Website]         {@link https://www.primeai.co.uk/}
 * @copyright 2018-2023 PRIME AI LTD
 */

import SentryClient from './public/sentry/SentryClient';

const sentryClient = new SentryClient('https://d582f8e142202d5879b81a2dc4ce470b@o4505244581756928.ingest.us.sentry.io/4506274882846720');

(async (sentry) => {
    try {
        // Dynamic Path Resolution.
        const __PRIME_AI_ENVIRONMENT__ = {
            HOST: `${document.currentScript.src.split('/').slice(0, 3).join('/')}/`,
            PATH: document.currentScript.src.split('/loader.js')[0],
            RETAILER_ID: document.currentScript.getAttribute('parent-id'),
            CHILD_ID: document.currentScript.getAttribute('child-id'),
            LANGUAGE_OVERRIDE: document.currentScript.getAttribute('language-code'),
            PRODUCT_ID_OVERRIDE: document.currentScript.getAttribute('product-id'),
            VERSION: '0.6.2',
            SENTRY: sentry,
        };

        // Sentry Setup.
        sentry.setContextDefault(__PRIME_AI_ENVIRONMENT__);

        // Other possible arguments from the script tag.
        // const scriptAttributes = document.currentScript.attributes;
        // __PRIME_AI_ENVIRONMENT__.LANGUAGE = scriptAttributes.getNamedItem('language-code')?.value || undefined;

        // Files to be loaded in.
        __PRIME_AI_ENVIRONMENT__.RETAILER_LOGIC_URL = `${__PRIME_AI_ENVIRONMENT__.PATH}/retailerLogic/${__PRIME_AI_ENVIRONMENT__.RETAILER_ID}.js`;
        __PRIME_AI_ENVIRONMENT__.SCRIPT_URL = `${__PRIME_AI_ENVIRONMENT__.PATH}/fcbp.js`;

        // Expose to all.
        window.__PRIME_AI_ENVIRONMENT__ = __PRIME_AI_ENVIRONMENT__;

        // Our load function.
        const load = (scriptName) => {
            const script = document.createElement('script');
            script.src = scriptName;
            script.async = true;
            document.head.appendChild(script);
            return true;
        };

        // Load the main widget file
        load(__PRIME_AI_ENVIRONMENT__.SCRIPT_URL);

        // Load the retailers file
        load(__PRIME_AI_ENVIRONMENT__.RETAILER_LOGIC_URL);
    } catch (error) {
        console.error('PRIME AI FCBP - Failed to load resources');
        console.error(error);
        sentry.captureException(error);
    }
})(sentryClient);
