import {
    BrowserClient,
    defaultStackParser,
    getDefaultIntegrations,
    makeFetchTransport,
    Scope,
} from '@sentry/browser';

export default class SentryClient {
    constructor(dsn) {
        this.dsn = dsn;
        this.integrations = this.getIntegrations();
        this.client = this.getBrowserClient();
        this.scope = this.getScope();

        // Automatically initialize the client.
        this.init();
    }

    init() {
        this.client.init();
    }

    getIntegrations() {
        return getDefaultIntegrations({}).filter((defaultIntegration) => !['BrowserApiErrors', 'TryCatch', 'Breadcrumbs', 'GlobalHandlers'].includes(defaultIntegration.name));
    }

    getBrowserClient() {
        return new BrowserClient({
            dsn: this.dsn,
            transport: makeFetchTransport,
            stackParser: defaultStackParser,
            integrations: this.integrations,
            allowUrls: [/primeai/],
        });
    }

    getScope() {
        const scope = new Scope();
        scope.setClient(this.client);
        return scope;
    }

    captureException(exception) {
        this.scope.captureException(exception);
    }

    setContextDefault(__PRIME_AI_ENVIRONMENT__) {
        this.scope.setTag('parentId', __PRIME_AI_ENVIRONMENT__.RETAILER_ID);
        this.scope.setTag('childId', __PRIME_AI_ENVIRONMENT__.CHILD_ID);
        this.scope.setTag('url', window.location.href);
        this.scope.setTag('version', __PRIME_AI_ENVIRONMENT__.VERSION);
        this.scope.setTag('languageOverride', __PRIME_AI_ENVIRONMENT__.LANGUAGE_OVERRIDE);
        this.scope.setTag('host', __PRIME_AI_ENVIRONMENT__.HOST);
        this.scope.setTag('productIdOverride', __PRIME_AI_ENVIRONMENT__.PRODUCT_ID_OVERRIDE);
        this.scope.setContext('ScriptDetails', {
            script: document.currentScript.src,
            version: __PRIME_AI_ENVIRONMENT__.version,
        });
        this.scope.setContext('Retailer', {
            parentId: __PRIME_AI_ENVIRONMENT__.RETAILER_ID,
            childId: __PRIME_AI_ENVIRONMENT__.CHILD_ID,
            url: window.location.href,
        });
        this.scope.setContext('BrowserDetails', {
            userAgent: window.navigator.userAgent,
            language: window.navigator.language,
            platform: window.navigator.platform,
            vendor: window.navigator.vendor,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            appVersion: window.navigator.appVersion,
            appName: window.navigator.appName,
            appCodeName: window.navigator.appCodeName,
            product: window.navigator.product,
            productSub: window.navigator.productSub,
            buildID: window.navigator.buildID,
            oscpu: window.navigator.oscpu,
            hardwareConcurrency: window.navigator.hardwareConcurrency,
            maxTouchPoints: window.navigator.maxTouchPoints,
            doNotTrack: window.navigator.doNotTrack,
            cookieEnabled: window.navigator.cookieEnabled,
        });
    }
}
